import React from "react"
import cx from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import upperFirst from "lodash/upperFirst"

import { useTranslation } from "../helpers/useTranslation"
import FormattedLink from "./formattedLink/formattedLink"
import Layout from "./layout"
import PageBuilder from "./pageBuilder/pageBuilder"

import * as styles from "../pages/pages.module.scss"

const NotFound = props => {
  const { t } = useTranslation()

  const contentData = props?.data?.sanityNotFoundPage
  
  return (
    <Layout>
      <div className={styles.notFound}>
        <h1>{t("oops")}</h1>
        <div className={styles.imageContainer}>
          <StaticImage
            src="../../assets/images/404.webp"
            alt="not-found"
            placeholder="blurred"
            width={564}
            height={288}
          />
        </div>
        <p>{t("page not found message")}</p>
        {contentData?._rawPageBuilder && <PageBuilder pageBuilder={contentData?._rawPageBuilder} />}
        <FormattedLink
          className={cx("btn btn-gradient", styles.backHomeButton)}
          label={upperFirst(t("back to homepage"))}
          url="/"
        />
      </div>
    </Layout>
  )
}

export default NotFound
