import React from "react"
import { graphql } from "gatsby"

import NotFound from "../components/notFound"

const NotFoundPage = props => {
  return <NotFound {...props} />
}

export const query = graphql`
  {
    sanityNotFoundPage(_id: { eq: "notFoundPage" }) {
      _id
      _rawPageBuilder(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default NotFoundPage
